/*
  Bold = Circular Bold
  Med  = Circular Medium
  Norm = Circular Book
*/

 body {
  margin: 0;
  /*font-family: CircularXX, Helvetica, Roboto, Arial, sans-serif;*/
  /*-webkit-font-smoothing: antialiased;*/
  /*-moz-osx-font-smoothing: grayscale;*/
}

#root {
  position: fixed;
  overflow-y: hidden;
  width: 100%;
  height: 100%;
}

/***** Temp CSS animations *****/
.slideLeft-enter-active,
.slideRight-enter-active,
.slideUp-enter-active,
.fade-enter,
.fade-exit,
.fade-enter-active,
.fade-exit-active {
  transition: all 300ms;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}

.fade-exit .headingArea {
  opacity: 0;
}

/* SlideLeft */
.slideLeft-enter,
.slideLeft-exit-active {
  opacity: 0;
  transform: translateX(-100px);
}
.slideLeft-enter-active,
.slideLeft-exit {
  opacity: 1;
  transform: translateX(0);
}

/* SlideRight */
.slideRight-enter,
.slideRight-exit-active {
  opacity: 0;
  transform: translateX(100px);
}
.slideRight-enter-active,
.slideRight-exit {
  opacity: 1;
  transform: translateX(0);
}

/* SlideUp */
.slideUp-enter,
.slideUp-exit-active {
  opacity: 0;
  transform: translateY(100px);
}
.slideUp-enter-active,
.slideUp-exit {
  opacity: 1;
  transform: translateY(0);
}

/* Fade */
.fade-enter,
.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit {
  opacity: 1;
}

/** illustration animations **/
.checkmark {
  width: 132px;
  height: 132px;
  border-radius: 100%;
  display: block;
  margin: 0 auto 0;
  box-shadow: inset 0px 0px 0px #28b440;
  animation: fill 0.4s ease-in-out 0.4s forwards, scale 0.3s ease-in-out 0.9s both;
}

.checkmark__circle {
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: #28b440;
  fill: none;
  animation: stroke 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
}

.checkmark__check {
  transform-origin: 35% 50%;
  transform: scale(0.3);
}

@keyframes stroke {
  100% {
    stroke-dashoffset: 0;
  }
}

@keyframes scale {
  0%,
  100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.1, 1.1, 1);
  }
}

@keyframes fill {
  100% {
    box-shadow: inset 0px 0px 0px 85px #28b440;
    border: 14px solid rgba(40, 180, 64, 0.2);
  }
}
